import React from 'react';
import styled from 'styled-components';
import { Config } from 'u-next/config';
import Footer from './Footer';
import Header from './Header';

const LayoutHelloero: React.FC<{
  children: React.ReactNode;
  options: Config;
}> = ({ children, options }) => (
  <Container>
    <Header options={options} />
    <Main>{children}</Main>
    <Footer options={options} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${({ theme }) => theme.keyColor.color1};
  padding: 0 0.5rem;
`;

const Main = styled.div`
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 4.5rem 0 5rem;
  background-color: ${({ theme }) => theme.background.primary};
`;

export default LayoutHelloero;
